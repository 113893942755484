
$(document).on('turbolinks:load', function() {


	console.log("héééééééé");

  


  $("#polling-modal").on('shown.bs.modal',  function() {
    
  	console.log("hummm");

    $(".pollingPoints").css("display", "none");
    $(".payment-gateways").css("display", "none");

  

    $("#polling_polling_category_id").on('change',  function(event) {
      console.log("change");

      var category = $("#polling_polling_category_id").find(":selected").text();
     

      if (category == "Vote payant"){
      $(".pollingPoints").css("display", "block");
      $(".payment-gateways").css("display", "block");
    }else{
      $(".pollingPoints").css("display", "none");
      $(".payment-gateways").css("display", "none");
    }

    });

  });

  });




