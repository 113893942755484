$(document).on('turbolinks:load', function() {

	/*
	function decrease_quantity(){
	  console.log("DECREASE: ", event.target );
	};
	*/

	//var swiperPrev = document.querySelector(`${section} .swiper-button-prev`);
	$(".third_party_phone").css("display", "none");

	$('#pay_for_third_party').click (function (){
		var thisCheck = $(this);
		if (thisCheck.is (':checked')){
			$(".third_party_phone").css("display", "block");
		}else{
			$(".third_party_phone").css("display", "none");
		}
	});

	window.decrease_quantity = function() {
	  console.log("DECREASE: ", event.target.id );
	  target_id = event.target.id;
	  target_uid = target_id.split("_")[2];
	  console.log("TARGET_UID: ", target_uid);
	  
	  var price = document.querySelector("#price_"+target_uid).innerText;
	  var quantity = $("#quantity_"+target_uid ).val();
	  var total = document.querySelector("#total").innerText;

	  var price = Number(price.split(' ').join(''));


	  console.log("QUANTITY: ", quantity);
	  quantity = Number(quantity);
	  total = Number(total);

	  
	  if (quantity > 0){
	  	quantity = quantity - 1;
	  	$("#quantity_"+target_uid ).val(quantity);
	  	total = total - (price);
	  
	  document.querySelector("#total").innerText = total;
	  }

	  
	 
	  
	}

	window.increase_quantity = function() {
	  console.log("DECREASE: ", event.target.id );
	  target_id = event.target.id;
	  target_uid = target_id.split("_")[2];
	  
	  console.log("TARGET_UID: ", target_uid);
	  
	  var price = document.querySelector("#price_"+target_uid).innerText;
	  var quantity = $("#quantity_"+target_uid ).val();
	  var total = document.querySelector("#total").innerText;

	  var price = Number(price.split(' ').join(''));
	  
	 

	  console.log("PRICE: ", price);
	  console.log("QUANTITY: ", quantity);
	  console.log("TOTAL: ", parseInt(total));
	  
	  quantity = Number(quantity);
	  total = Number(total);
	  
	  quantity = quantity + 1;
	  //total = total + (quantity * price);
	  total = total + (price);
	  console.log("NEW TOTAL: ", total);
	  $("#quantity_"+target_uid ).val(quantity);
	  document.querySelector("#total").innerText = total;
	  
	  
	}
});