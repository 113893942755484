$(document).on('turbolinks:load', function() {
	$('.media-wrapper audio').mediaelementplayer({

	  // more configuration here

		
	  iconSprite: './mejs-controls.svg',
	  useDefaultControls: true,
	  alwaysShowControls: true,

	});

});